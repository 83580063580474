import React from "react";

function Class({ num }) {
    return (
        <li className="home__class">
            {`${num} класс`}
        </li>
    )
}

export default Class;
import React from "react";

function SpinnerMain() {
  return (
    <div className="animate__container">
      <h2 className="animate">Загрузка...</h2>
    </div>
  );
}

export default SpinnerMain;
